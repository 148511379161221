let msgNum = {
	methods: {
		// 所有消息总数-1
		_msgTotalNumMinus1 () {
			let sessData = JSON.parse(sessionStorage.getItem('unReadMsgNum'))
			sessData = Object.assign({}, sessData)
			// 总数-1
			sessData.totalMessageNum--
			// 找到1级菜单项，角标数量-1
			this.$parent.$parent.$parent.$parent.$parent.$children.filter(v => v.totalMSgNum)[0].totalMSgNum--
			sessionStorage.setItem('unReadMsgNum', JSON.stringify(sessData))
		},
		// 返回指定key的消息数量
		_getMsgNum(k) {
			let sessData = JSON.parse(sessionStorage.getItem('unReadMsgNum'))
			return sessData[k]
		}
	}
}
export { msgNum }